import axios from 'axios';

axios.defaults.baseURL = 'https://api.approval.autobit.ch/';

class API {
  constructor()
  {
    this.endpoints = {};
    this.endpoints.user = this.createBasicCRUDEndpoints({ name: 'user' });

    this.openEndpoints = {};
    this.openEndpoints.typeApproval = this.createBasicCRUDOpenEndpoints({ name: 'type-approval' });
  }

  createBasicCRUDOpenEndpoints = ({ name }) => {
    const openEndpoints = {};

    const resourceURL = `v1/${name}`;

    openEndpoints.getAll = (config = { crossDomain: true }) =>  axios.get(`${resourceURL}/`, config).catch(err => console.log('error'));
    openEndpoints.getOne = ({ id }, config = { crossDomain: true }) => axios.get(`${resourceURL}/${id}/`, config).catch(err => console.log('error'));

    return openEndpoints;
  }

  createBasicCRUDEndpoints = ({ name }) => {

    var authToken = '';

    if (localStorage.getItem('token') !== null)
    {
      authToken = localStorage.getItem('token');
    }

    const endpoints = {};

    const resourceURL = `v1/${name}`;

    endpoints.getAll = (config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) =>  axios.get(`${resourceURL}/`, config).catch(err => console.log('error'));
    endpoints.getOne = ({ id }, config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) => axios.get(`${resourceURL}/${id}/`, config).catch(err => console.log('error'));
    endpoints.create = (toCreate, config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) => axios.post(`${resourceURL}/`, toCreate, config).catch(err => console.log('error'));
    endpoints.update = (toUpdate, config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) => axios.put(`${resourceURL}/${toUpdate.id}/`, toUpdate, config).catch(err => console.log('error'));
    endpoints.patch = ({ id }, toPatch, config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) => axios.patch(`${resourceURL}/${id}/`, toPatch, config).catch(err => console.log('error'));
    endpoints.delete = ({ id }, config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) => axios.delete(`${resourceURL}/${id}/`, config).catch(err => console.log('error'));

    return endpoints;
  }
}

export default API;
