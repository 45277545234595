import React, { useEffect, Fragment } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
// Import pages
import OpenFront from './pages/OpenFront';

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]);

  return (
    <>
      <Switch>
        <Route path="/" component={OpenFront}/>
      </Switch>
    </>
  );
}
export default App;
