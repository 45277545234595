import React from 'react';
import CustomScrollbars from './CustomScrollbars';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function TypeApproval(props)
{
  var printData = clean(props.data);

  console.log(printData);

  var keyName = {
    tg_codes: "Typengenehmigungsnummer",
    fzart: "Fahrzeugart",
    fzsart: "Fahrzeugsubart",
    karart: "Karosserieform",
    techtyp: "Typ; Variante/Version",
    vincode: "Vorziffer",
    gelfz: "Geländefahrzeug",
    herplk: "Herstellerplakette",
    fgstnr: "Fahrgestellnummer",
    achse: "Achsen/Räder",
    feder: "Federung",
    lenkung: "Lenkung",
    antrieb: "Achsantrieb",
    betbre1: "Betriebsbremse 1",
    betbre2: "Betriebsbremse 2",
    betbre3: "Betriebsbremse 3",
    hilfbre1: "Hilfsbremse 1",
    hilfbre2: "Hilfsbremse 2",
    festbre: "Feststellbremse",
    dauerbre: "Dauerbremse",
    treib: "Bauart Treibstoff",
    bauart: "Bauart",
    kat: "Abgasreinigung",
    daempfer1: "Schalldämpfer 1 Bez",
    daempfer2: "Schalldämpfer 2 Bez",
    daempfer3: "Schalldämpfer 3 Bez",
    daempferb1: "Schalldämpfer 1 Art",
    daempferb2: "Schalldämpfer 2 Art",
    daempferb3: "Schalldämpfer 3 Art",
    mkenzanb: "Motorkennzeichen Anbringungsort",
    gerdamp1: "Geräuschdämpfung 1",
    gerdamp2: "Geräuschdämpfung 2",
    gerdamp3: "Geräuschdämpfung 3",
    gerdamp4: "Geräuschdämpfung 4",
    anztuer: "Anzahl Türen",
    rspiegel: "Rückspiegel",
    besaus1: "Bes. Ausrüstung 1",
    besaus2: "Bes. Ausrüstung 2",
    besaus3: "Bes. Ausrüstung 3",
    kdlast: "keine Dachlast",
    kalast: "keine Anhängelast",
    reifen1: "Reifen Felgen 1",
    reifen2: "Reifen Felgen 2",
    reifen3: "Reifen Felgen 3",
    anhreif1: "Reifen und Felgen 1",
    anhreif2: "Reifen und Felgen 2",
    anhreif3: "Reifen und Felgen 3",
    anhreif4: "Reifen und Felgen 4",
    anhreif5: "Reifen und Felgen 5",
    anhreif6: "Reifen und Felgen 6",
    abgbem: "Reifen und Felgen 7",
    rbem: "Emissionen Rauch Bem",
    gbem1: "Emissionen Geräusch Bem 1",
    gbem2: "Emissionen Geräusch Bem 2",
    gbem3: "Emissionen Geräusch Bem 3",
    katbez: "Abgasreinigung Bez",
  }

  function clean(obj)
  {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj
  }

  function formatValue(value)
  {
    var valueSplit = value.split(";");
    return valueSplit
  }

  return (
    <div>
      <DialogTitle>Typen Genehmigung - {printData['tg_codes']}</DialogTitle>
      <DialogContent>
        <div class="openfront-result-container">
          <CustomScrollbars style={{ height: '100%', width: '100%' }}>
            { Object.keys(printData).map(function(printKey, index){
              return (
                <div class="openfront-result-box">
                  <div class="openfront-result-key">{keyName[printKey]}</div>
                  <div class="openfront-result-value">{ printData[printKey].includes(';') ?
                    <>
                      {
                        formatValue(printData[printKey]).map(function(keyValue, idx){
                        return (
                          <div class="openfront-result-value-spezial">
                            {keyValue}
                          </div>
                          )
                        }
                      )}
                    </>
                    :
                    <>
                      {printData[printKey]}
                    </>
                  }</div>
                </div>
              )
            }
            )}
          </CustomScrollbars>
        </div>
      </DialogContent>
      <DialogActions>
        <div class="p-line-end">
          <span></span>
          <Button variant="contained" onClick={props.close} >
            Schliessen
          </Button>
          <span></span>
        </div>
      </DialogActions>
    </div>
  );
}
