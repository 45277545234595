import React, { useState, useEffect } from 'react';
import '../css/openfront.css';
import Logo from '../images/approval.svg'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom'
import API from '../api/api';
import TypeApproval from '../components/TypeApproval';
import Dialog from '@material-ui/core/Dialog';
import InputMask from "react-input-mask";
import CircularProgress from '@material-ui/core/CircularProgress';

const approvalApi = new API();

const OpenFront = () => {
  const [typeApproval, setTypeApproval] = useState('');
  const [typeApprovalResult, setTypeApprovalResult] = useState('');
  const [errors, setErrors] = useState('');
  const [dialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    setErrors('');

    if(typeApproval.length === 6)
    {
      setLoading(true);
      approvalApi.openEndpoints.typeApproval.getOne({ id: typeApproval })
        .then((approvalGet) => {
          setLoading(false);
          if(approvalGet.data[0])
          {
            setTypeApprovalResult(approvalGet.data);
            setDialog(true);
          }
          else
          {
            setErrors('Es wurde keine Typgenehmigung gefunden');
          }
        });
    }
    else
    {
      setTypeApprovalResult('');
      setErrors('Es werden 6 Zeichen benötigt');
    }
  };

  const closeDialog = () => {
    setDialog(false);
  };

  return (
    <div class="openfront-back">
      <Dialog fullWidth="true" maxWidth="lg" open={dialog} onClose={closeDialog}>
        <TypeApproval data={typeApprovalResult[0]} close={closeDialog.bind(this)}/>
      </Dialog>
      <span></span>
      <div class="openfront-center">
        <span></span>
        <div class="openfront-window">
          <div class="openfront-logo">
            <img class="openfront-logo-image" src={Logo} alt="Logo" />
          </div>
          <div class="openfront-title">Typen Genehmigung</div>
          {errors !== '' && <div class="openfront-error">{errors}</div>}
          {loading ?
            <div class="center-loader"><span></span><CircularProgress /><span></span></div>
            :
            ''
          }
          <form onSubmit={onSubmit}>
            <div class="openfront-placeholder"></div>
            <div class="openfront-input">
              <span></span>
              <InputMask mask="tmmttt"
                value={typeApproval}
                onChange={e => setTypeApproval(e.target.value)}
                formatChars={{"t": "[0-9]","m": "[A-Z]"}}
                maskChar={null}
              >
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    required
                    margin="dense"
                    id="typeApproval"
                    label="Nummer"
                    type="text"
                    fullWidth
                    variant="outlined"
                  />
                )}
              </InputMask>
              <span></span>
            </div>
            <div class="openfront-placeholder"></div>
            <div class="openfront-buttons">
              <span></span>
              <Button type="submit" variant="contained" color="default">
                Öffnen
              </Button>
              <span></span>
            </div>
            <div class="openfront-placeholder"></div>
          </form>
        </div>
      </div>
      <span></span>
    </div>
  );
}

export default OpenFront;
